import { useStaticQuery, graphql } from "gatsby";
import { trim, trimEnd } from "lodash";

export const useAbsoluteUrl = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const buildAbsoluteUrl = (...paths: string[]) =>
    [
      trimEnd(site.siteMetadata.siteUrl, "/"),
      ...paths.map((path) => trim(path, "/")),
    ].join("/");

  const buildAbsoluteUrlWithTrailingSlash = (...paths: string[]) =>
    trimEnd(buildAbsoluteUrl(...paths), "/") + "/";

  return {
    buildAbsoluteUrl,
    buildAbsoluteUrlWithTrailingSlash,
  };
};
