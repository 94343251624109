import React, { FunctionComponent } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { ChevronRightIcon } from "@heroicons/react/solid";
import ServiceNavCta from "../../cta/ServiceNavCta";
import { useServiceTree } from "../../../hooks/useServiceTree";
import { useServiceI18nLink } from "../../../hooks/useI18nLink";

const ServiceNav: FunctionComponent = () => {
  const { t } = useTranslation();
  const services = useServiceTree();
  const { serviceLink } = useServiceI18nLink();

  return (
    <section className="container">
      <div className="px-6 md:px-12 pt-2 md:py-8 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-8">
        {services.map((service, i) => (
          <div key={service.id}>
            <Link to={serviceLink(service)}>
              <p className="font-bold text-gray-900 mb-3">
                {t(`services:${service.id}.name`)}
                <ChevronRightIcon className="inline h-5 w-5 -mt-[1px] ml-1" />
              </p>
            </Link>
            {service.solutions && (
              <ul className="grid grid-cols-2 md:grid-cols-2 gap-x-6 gap-y-2">
                {service.solutions.map((solution) => (
                  <Link key={solution.id} to={serviceLink(solution)}>
                    <li className="text-gray-700 hover:text-gray-900">
                      {t(`solutions:${solution.id}.name`)}
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <ServiceNavCta />
    </section>
  );
};

export default ServiceNav;
