declare const window: { dataLayer: object[] };

export enum GtmEven {
  applyFormSubmit = "apply_form_submit",
  contactFormSubmit = "contact_form_submit",
  iubendaPreferenceUpdated = "iubenda_preference_updated",
}

type gmtEvent = {
  event?: GtmEven;
  [key: string]: any;
};

export const usePushGtmEvent = () => (event: gmtEvent) => {
  window.dataLayer?.push(event);
};

export function gtag(...args) {
  window.dataLayer.push(arguments);
}

export const useGtag = () => gtag;
