import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby";

const ServiceNavCta = () => {
  const { t } = useTranslation();
  const { contactPhone } = useStaticQuery(graphql`
    query CardsNavCtaQuery {
      contactPhone: contactsJson(jsonId: { eq: "phone" }) {
        name
        url
      }
    }
  `);

  const onMailClick = () => {
    const exist = !!document.querySelector("#contact-form");
    if (!exist) navigate("/#contact-form");
  };

  return (
    <div className="hidden lg:flex px-5 py-5 bg-gray-100 space-y-6 sm:space-y-0 sm:px-8">
      <div className="flow-root lg:order-2 lg:ml-8">
        <a
          href={contactPhone.url}
          className="-m-3 p-3 flex lg:hidden items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-200 lg:hover:bg-gray-50 ml-0"
        >
          <svg
            className="flex-shrink-0 h-6 w-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <span className="ml-3">{t("navigationVoices.callUs")}</span>
        </a>
        <div className="hidden lg:flex -m-3 p-3 items-center rounded-md text-base font-medium text-gray-900 ml-0 cursor-auto">
          <svg
            className="flex-shrink-0 h-6 w-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <span className="ml-3">{contactPhone.name}</span>
        </div>
      </div>
      <div className="flow-root lg:order-1 ml-8 lg:ml-0">
        <a
          href="#contact-form"
          onClick={onMailClick}
          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-200 ml-0 cursor-pointer"
        >
          <svg
            className="flex-shrink-0 h-6 w-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <span className="ml-3">{t("navigationVoices.mailUs")}</span>
        </a>
      </div>
    </div>
  );
};

export default ServiceNavCta;
