import { useI18next } from "gatsby-plugin-react-i18next";
import { trim, last } from "lodash";
import { useServiceTree } from "./useServiceTree";

const linkMap = {
  en: {
    "lavora-con-noi": "work-with-us",
    "work-with-us": "work-with-us",
    "chi-siamo": "about-us",
    "about-us": "about-us",
  },
  it: {
    "lavora-con-noi": "lavora-con-noi",
    "work-with-us": "lavora-con-noi",
    "chi-siamo": "chi-siamo",
    "about-us": "chi-siamo",
  },
};

export const useI18nLink = () => {
  const { language } = useI18next();
  const serviceMap = useServiceTree();
  const services = [
    ...Object.values(serviceMap),
    ...Object.values(serviceMap).flatMap((s: any) => s.solutions || []),
  ];

  return (
    route: string,
    lang: string = language,
    fallback: boolean = true,
    anchor?: string
  ) => {
    const path = last(trim(route, "/").split("/"))!;
    const sanitizedRoute = `/${route.replace(/(^\/*|\/*$)/g, "")}/`;

    const res = linkMap[lang][path];

    if (res) return anchor ? `/${res}#${anchor}` : `/${res}/`;

    const service = services.find((s) => s.enSlug === path || s.slug === path);
    if (service) {
      const slug = {
        it: `servizi/${service.slug}`,
        en: `services/${service.enSlug}`,
      }[lang];
      return anchor ? `/${slug}#${anchor}` : `/${slug}/`;
    }

    if (fallback) return sanitizedRoute;

    return undefined;
  };
};

export const useServiceI18nLink = () => {
  const { language } = useI18next();

  const fn = (
    destination: { slug: string; enSlug: string },
    lang: string = language
  ) => {
    const route = {
      it: `servizi/${destination.slug}`,
      en: `services/${destination.enSlug}`,
    }[lang];
    return `/${route}/`;
  };

  return {
    serviceLink: (
      destination: { slug: string; enSlug: string },
      lang: string = language
    ) => fn(destination, lang),
  };
};
