import { useStaticQuery, graphql } from "gatsby";

export const useConfig = () => {
  const { config } = useStaticQuery(graphql`
    query ConfigQuery {
      config {
        iubenda {
          siteId
          privacyPolicyId {
            it
            en
          }
        }
        contactUs {
          endpoint
          callUrl
        }
        jobApplication {
          endpoint
        }
        seo {
          index
        }
        algolia {
          index
          appId
          searchKey
        }
      }
    }
  `);

  return config;
};
