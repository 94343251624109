import React, { FunctionComponent, useState } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import { useI18nLink } from "../../../hooks/useI18nLink";
import Language from "../../Language";
import ServiceNav from "./ServiceNav";

// @ts-ignore
import logo from "../../../assets/images/logo.svg";
// @ts-ignore
import logo_dark from "../../../assets/images/logo_dark.svg";

type HeaderProps = {
  active?: string;
  dark?: boolean;
  bgColor?: string;
};

const Header: FunctionComponent<HeaderProps> = ({
  active,
  dark,
  bgColor = "white",
}) => {
  const { t } = useTranslation();
  const i18nLink = useI18nLink();

  const navigation = {
    voices: [
      {
        name: "services",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        ),
        opens: <ServiceNav />,
      },
      {
        name: "about",
        link: i18nLink(`/chi-siamo/`),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
            />
          </svg>
        ),
      },
      {
        name: "join",
        link: i18nLink(`/lavora-con-noi/`),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        ),
      },
      {
        name: "resources",
        language: "it",
        link: "/risorse/",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
            />
          </svg>
        ),
      },
      {
        name: "blog",
        language: "it",
        link: "/blog/",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
        ),
      },
    ],
  };

  const [showNestedNavs, setShowNestedNavs] = useState(
    navigation.voices.map((_) => false)
  );
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <div className={`relative bg-${bgColor}`}>
      <div className="container mx-auto px-4 sm:px-6">
        <div className={`relative flex-col`}>
          <div className="flex justify-between items-center py-3 lg:justify-start lg:space-x-10">
            {/* LOGO */}
            <div className="flex justify-start">
              <Link to="/">
                <span className="sr-only">Astrorei</span>
                <img
                  className="h-auto w-36 sm:h-12 mt-[-5px]"
                  src={dark ? logo_dark : logo}
                  alt="Astrorei logo"
                />
              </Link>
            </div>

            <button
              type="button"
              aria-expanded="false"
              onClick={() => setShowMobileNav(true)}
              className="lg:hidden rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>

            <nav className="hidden lg:flex space-x-10">
              {navigation.voices.map((voice, i) =>
                voice.opens ? (
                  <div key={i} className="relative z-50">
                    <button
                      type="button"
                      onClick={() =>
                        setShowNestedNavs(
                          showNestedNavs.map((_, index) =>
                            index === i ? !showNestedNavs[i] : false
                          )
                        )
                      }
                      className={`inline-flex items-center text-md outline-none focus:outline-none
                        ${
                          voice.name === active
                            ? "font-semibold " +
                              (dark ? "text-white" : "text-gray-900")
                            : dark
                            ? "text-gray-300 hover:text-white"
                            : "text-gray-700 hover:text-gray-900"
                        }`}
                      aria-expanded="false"
                    >
                      <span>{t("navigationVoices." + voice.name)}</span>
                      <svg
                        className={`${
                          showNestedNavs[i] ? "hidden" : ""
                        } ml-2 h-5 w-5 text-gray-400`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <svg
                        className={`ml-2 text-gray-400 h-5 w-5 ${
                          showNestedNavs[i] ? "" : "hidden"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <Link
                    key={i}
                    to={voice.link!}
                    language={voice.language}
                    className={` z-50 text-md ${
                      voice.name === active
                        ? "font-semibold pointer-events-none " +
                          (dark ? "text-white" : "text-gray-900")
                        : dark
                        ? "text-gray-300 hover:text-white"
                        : "text-gray-700 hover:text-gray-900"
                    }`}
                  >
                    {t("navigationVoices." + voice.name)}
                  </Link>
                )
              )}
            </nav>
            <div className="z-50 flex-1 hidden lg:flex">
              <Language dark={dark}/>
            </div>
          </div>

          <div className="hidden max-w-4xl mx-auto absolute z-50 lg:flex">
            {navigation.voices.map(
              (voice, i) =>
                voice.opens && (
                  <div
                    key={i}
                    onBlur={() =>
                      setShowNestedNavs(
                        showNestedNavs.map((_, index) =>
                          index === i ? !showNestedNavs[i] : false
                        )
                      )
                    }
                    className={`transition-all  ${
                      showNestedNavs[i]
                        ? "max-h-screen duration-700 ease-in"
                        : "hidden max-h-0"
                    } w-full`}
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white overflow-hidden">
                      {voice.opens}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>

      {/* MOBILE NAV */}
      <div
        className={`${
          showMobileNav
            ? "visible opacity-100 scale-100 transition duration-200 ease-out"
            : "invisible opacity-0 scale-95 transition duration-100 ease-in"
        } absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-50`}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black overflow-hidden ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <Link to="/">
                <img className="h-12 w-auto" src={logo} alt="logo" />
              </Link>
              <button
                type="button"
                onClick={() => setShowMobileNav(false)}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-8">
              <nav>
                {navigation.voices.map((voice, i) =>
                  voice.opens ? (
                    <div key={i}>
                      <button
                        onClick={() =>
                          setShowNestedNavs(
                            showNestedNavs.map((_, index) =>
                              index === i ? !showNestedNavs[i] : false
                            )
                          )
                        }
                        className={`py-3 w-full flex items-center rounded-md focus:outline-none outline-none text-gray-700 ${
                          voice.name === active ? "font-semibold" : ""
                        }`}
                      >
                        <span className="h-6 w-6">{voice.icon}</span>

                        <span className="ml-3 text-base font-medium">
                          {t("navigationVoices." + voice.name)}
                        </span>

                        <svg
                          className={`ml-2 text-gray-400 h-6 w-6 ${
                            showNestedNavs[i] ? "hidden" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <svg
                          className={`ml-2 text-gray-400 h-6 w-6 ${
                            showNestedNavs[i] ? "" : "hidden"
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div className={`${showNestedNavs[i] ? "" : "hidden"}`}>
                        {voice.opens}
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={i}
                      to={voice.link!}
                      language={voice.language}
                      className={`py-3 text-gray-700 w-full flex z-50 text-md ${
                        voice.name === active &&
                        "font-sembibold pointer-events-none"
                      }`}
                    >
                      <span className="h-6 w-6">{voice.icon}</span>

                      <span className="ml-3 text-base font-medium ">
                        {t("navigationVoices." + voice.name)}
                      </span>
                    </Link>
                  )
                )}
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6 bg-gray-50">
            <Language />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
